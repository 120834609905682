@import '../../styles/customMediaQueries.css';

/* ModalInMobile is visible by default */
.root {
  width: 100%;
}

/* Content is hidden in Mobile layout */
.modalHidden {
  display: none;
}

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.modalContent {
  width: 100%;
  padding: 24px;
  @media (min-width: 1023px) {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }
  @media (--viewportMedium) {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible;
  }
  & form{
    @media (max-width: 1023px) {
      /* padding: 0 24px 24px; */
    }
  }
}
